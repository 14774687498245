import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CarbonPassHeader from "../components/carbonpassheader2"
import GetInTouch from "../components/getintouch"
import { Link } from "gatsby"
import CloudOne from "../images/plane.svg"
import bgsky from "../images/bg-cloud.svg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <CarbonPassHeader/>
    <div className="relative">
      <div className="-mb-6 block pt-4 p-6 text-white bg-gradient-to-b from-carbonblue to-carbonbluelight">
          <img className='animate-[movingCloud_50s_linear_infinite] opacity-20' src={CloudOne} alt="Cloud" style={{height:"140px"}}></img>
         
          <div className="max-w-7xl mx-auto h-48 text-center">

          <h2 className="mt-10 text-5xl lg:text-7xl font-bold text-white mb-8 lg:mb-12">
          Page not found
          </h2>
        <Link to="/"><button className="text-2xl mb-0 lg:mb-8 bg-carbongreen rounded-lg mt-3 py-2 px-10 mt-8 transition duration-500 ease-in-out bg-blue-500 hover:bg-black transform hover:-translate-y-1 hover:scale-110 text-white">Return to Home</button></Link>
  
          </div>
          <div className='animate-[movingCloudRight_150s_linear_infinite] bg-cover block' style={{backgroundImage: `url(${bgsky})`, width:"4000px",height:"200px"}}></div>
         
      </div>
         </div>
    <GetInTouch/>
  </Layout>
)

export default NotFoundPage
